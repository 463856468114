export default {
  geral: {
    app: 'Retaguarda',
    vertical: 'System',

    navegacao: {
      saude: 'Health',
      idiomas: 'Languages',
      educacional: 'Educational',
      servicos: 'Services',
      empresas: 'Companies',
      usuarios: 'Users',
      faturas: 'Invoices',
      mensalidades: 'Monthly payment'
    },
    feedbacks: {
      arquivoEnviadoSucesso: 'File uploaded successfully!',
      arquivoEnviadoErro: 'Error sending file!',
      somenteUmArquivo: 'Insert only one file!',
      insiraArquivo: 'insert a file!',
      arquivoSQLObrigatorio: 'The file extension must be .sql'
    }
  },

  dashboard: {
    information: 'There is nothing around here :(',
    instruction: 'Register a company to generate data in the dashboard',
    categorias: {
      contas: 'Accounts',
      licencas: 'Lincenses',
      ativacoes: 'Activations',
      semContas: 'No Accounts',
      integracoes: 'Integrations',
      semLicencas: 'No Licenses',
      semAtivacoes: 'No Activations',
      semIntegracoes: 'No Integrations'
    }
  },

  cadastro: {
    empresas: {
      titulo: 'Companies',
      novo: 'New company',
      cadastradoSucesso: 'Successfully registered company!',
      atualizadoSucesso: 'Company updated successfully!',
      removidoSucesso: 'Company successfully removed!',
      subdominio: 'Subdomain',
      verticalMercado: 'Market vertical',
      codigoCliente: 'Customer code',
      perfilAdministrativo: 'Administrative profile',
      implantacao: 'Implantation',
      acessarEmpresa: 'Access company',
      empresaEmProcessamento: 'Company in the process of creation. Hold!',
      processamentoFalhou: 'The build process failed! Contact the Product Manager.',
      tarifas: 'Rates',
      debugs: 'Debugs',
      dadosTecnicos: 'Technical Data',
      dadosIntegracao: 'Data for Integration',
      quadroSocietario: 'Corporate structure',
      novoSocio: 'Add member',
      editarSocio: 'Edit member',
      tipoIntegracao: 'Integration Type',
      falhas: 'Failures',
      relacionamento: 'Relationship',
      integracao: 'Integration',
      servicos: 'Services',
      valor: 'Value',
      editarValor: 'Edit value',
      valorServico: 'Service value',
      tarfifaAtualizadoSucesso: 'Rate updated successfully!',
      servicosInformation: 'There is nothing around here :(',
      servicosInstruction: 'No services registered for this rate',
      nenhumaMensagemDebug: 'No debug messages logged',
      mensagem: 'Message',
      data: 'Date',
      nenhumaMensagemFalha: 'No fault message recorded',
      idInterno: 'Internal id',
      idExterno: 'External id',
      entidadeOrigem: 'Home entity',
      entidadeDestino: 'Destination entity',
      nenhumRelacionamento: 'No relationship registered',
      detalhesFalha: 'Fault Details',
      information: 'Information',
      tenantName: 'Tenant',
      tenantId: 'TenantId',
      registryName: 'RegistryName',
      registryId: 'RegistryId',
      timestamp: 'Timestamp',
      retried: 'Retried',
      exceptions: 'Exceptions',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Message',
      source: 'Source',
      host: 'Host',
      machineName: 'MachineName',
      processName: 'ProcessName',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      faultMessageTypes: 'FaultMessageTypes',
      nenhumaException: 'Nenhuma exception registrada',
      actions: 'Actions',
      retry: 'Retry',
      retryEfetuadoSucesso: 'Retry successful',
      configuracoes: 'Settings',
      key: 'Key',
      value: 'Value',
      atributos: 'Attributes',
      endpoints: 'Endpoints',
      atributoAtualizadoSucesso: 'Attribute updated successfully',
      method: 'Method',
      name: 'Name',
      uri: 'URI',
      endpointAtualizadoSucesso: 'Endpoint updated successfully',
      somenteIntegracao: 'Integration only',
      id: 'Integration id',
      hooks: 'Hooks',
      novoHook: 'New hook',
      evento: 'Event',
      url: 'Url',
      metodo: 'Method',
      webhook: 'Webhook',
      hookCriadoSucesso: 'Hook created successfully',
      hookAtualizadoSucesso: 'Hook updated successfully',
      hookRemovidoSucesso: 'Hook successfully removed',
      headers: 'Headers',
      novoHeader: 'New header',
      chave: 'Key',
      headerCriadoSucesso: 'Successfully created header',
      headerAtualizadoSucesso: 'Successfully updated header',
      headerRemovidoSucesso: 'Header successfully removed',
      situacao: 'Situation',
      statusAtualizadoSucesso: 'Status updated successfully',
      migracao: 'Migration',
      clienteMigracao: 'Client Migration',
      migracaoFinal: 'Final Migration',
      idReferenciaTenant: 'Tenant Reference ID',
      arquivoBancoDeDados: 'Database file',
      transferenciaAtualizadoSucesso: 'Transfer updated successfully',
      dataInicialCobranca: 'Initial billing date',
      backups: {
        baseDados: 'Data base',
        solicitacoesBackup: 'Backup Requests',
        editarSolicitacaoBackup: 'Edit backup request',
        novaSolicitacao: 'New Request',
        exclusaoBase: 'Delete from the database',
        excluirBase: 'Delete database',
        excluirBaseBtn: 'Delete base',
        novaSolicitacaoBackup: 'New backup request',
        motivo: 'Reason',
        dataSolicitacao: 'Request date',
        situacaoSolicitacao: 'Request status',
        gerado: 'Backup generated',
        pendente: 'Pending Request',
        recebidoCliente: 'Received by the customer',
        nomeRecebedor: 'Receiver name',
        cadastradoSucesso: 'Request registered successfully',
        atualizadoSucesso: 'Request updated successfully',
        valorNaoConfere: 'The data informed does not match. Please verify.',
        baseDeletadaSucesso: 'Process completed successfully',
        deletadoPor: 'Responsible for the exclusion',
        dataExclusao: 'Exclusion date',
        confirmacaoDados: 'Data confirmation',
        situacaoContratual: 'Contract status',
        mensagemExclusao: {
          titulo: 'Caution!!',
          conteudo1: 'This base exclusion process is',
          irreversivel: 'irreversible.',
          conteudo2: 'As you proceed, make sure the backup has been taken and sent to the client.'
        },
        dialogExclusao: {
          titulo: 'Caution!',
          baseDe: 'The companys database:',
          seraExcluida: 'Will be permanently deleted.',
          processo: 'This process is ',
          irreversivel: 'irreversible ',
          usuarioRegistrado: 'and your user will be registered as responsible..',
          desejaProsseguir: 'Do you want to proceed?'
        }
      },

      historicoContratacoes: {
        titulo: 'Hiring History',
        mesAtual: 'Current month',
        resumo: 'Summary',
        movimentoUsuarios: 'User movement',
        quantidadeUsuarios: 'Number of users',
        valorMensalidade: 'Monthly fee',
        dataMedico: 'Date / Doctor',
        emailUsuario: 'Email / User',
        nenhumaMovimentacaoEncontrada: 'No drives found'
      },

      qtdeAlunos: 'Number of students',
      qtdeMedicos: 'Number of doctors',
      justificativaAlteracaoTarifaMensalidade: 'Reason for rate/monthly change'
    },

    integracoes: {
      titulo: 'Services',
      atualizadoSucesso: 'Service updated successfully!',
      integracaoAtiva: 'Active Integration',
      ultimaVerificacao: 'Last check',
      preTaxas: 'Pre fees',
      hooks: 'Hooks',
      nenhumHook: 'No webhooks received',
      data: 'Date',
      api: 'API',
      processado: 'Processed',
      webhook: 'Webhook',
      tenantId: 'TenantId',
      tenant: 'Tenant',
      exceptions: 'Exceptions',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Message',
      source: 'Source',
      host: 'Host',
      machineName: 'MachineName',
      processName: 'ProcessName',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      findedRelationship: 'Finded relationship',
      nomeEndpoint: 'Endpoint name',
      entidadeInterna: 'Internal entity',
      idInterno: 'Internal id',
      idExterno: 'External id',
      fieldInterno: 'Internal field',
      nenhumaExcecao: 'No exceptions found',
      nenhumRelacionamento: 'No relationships found'
    },

    usuarios: {
      titulo: 'Users',
      novo: 'New user',
      cadastradoSucesso: 'Successfully registered user!',
      atualizadoSucesso: 'User updated successfully!'
    }
  },

  financeiro: {
    abertas: 'Open',
    fechadas: 'Closed',
    finalizadas: 'Finished',
    liquidadas: 'Settled',

    mensalidades: {
      tarifaPadraoMensalidadeAluno: 'Value per student',
      tarifaPadraoMensalidadeMedico: 'Value per doctor',
      tarifaPadraoMensalidade: 'Value per student'
    },
    faturas: {
      fechamentoFatura: 'Invoice closing',
      vencimentoFatura: 'Invoice Due Date'
    }
  }
}
