"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var icons = {
  'spt-world': {
    path: _react.default.createElement("path", {
      d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 17.93C5.05 17.44 2 14.08 2 10C2 9.38 2.08 8.79 2.21 8.21L7 13V14C7 15.1 7.9 16 9 16V17.93ZM15.9 15.39C15.64 14.58 14.9 14 14 14H13V11C13 10.45 12.55 10 12 10H6V8H8C8.55 8 9 7.55 9 7V5H11C12.1 5 13 4.1 13 3V2.59C15.93 3.78 18 6.65 18 10C18 12.08 17.2 13.97 15.9 15.39Z",
      fill: "currentColor"
    })
  },
  'spt-linguistic': {
    path: _react.default.createElement("path", {
      d: "M11.87 13.07L9.33 10.56L9.36 10.53C11.1 8.59 12.34 6.36 13.07 4H16V2H9V0H7V2H0V4H11.17C10.5 5.92 9.44 7.75 8 9.35C7.07 8.32 6.3 7.19 5.69 6H3.69C4.42 7.63 5.42 9.17 6.67 10.56L1.58 15.58L3 17L8 12L11.11 15.11L11.87 13.07ZM17.5 8H15.5L11 20H13L14.12 17H18.87L20 20H22L17.5 8ZM14.88 15L16.5 10.67L18.12 15H14.88Z",
      fill: "currentColor"
    })
  },
  'spt-filter-completed': {
    path: _react.default.createElement("path", {
      d: "M21.3809 4C19.3709 6.59 14.3809 13 14.3809 13V20H10.3809V13C10.3809 13 5.40086 6.59 3.38086 4H21.3809Z",
      fill: "white"
    })
  },
  'spt-keyboard-return': {
    path: _react.default.createElement("path", {
      d: "M5 7V11H18.17L14.59 7.41L16 6L22 12L16 18L14.59 16.59L18.17 13H3V7H5Z",
      fill: "white"
    })
  },
  'spt-finger-click': {
    path: _react.default.createElement("path", {
      d: "M15.75 9.24V5.5C15.75 4.12 14.63 3 13.25 3C11.87 3 10.75 4.12 10.75 5.5V9.24C9.54 8.43 8.75 7.06 8.75 5.5C8.75 3.01 10.76 1 13.25 1C15.74 1 17.75 3.01 17.75 5.5C17.75 7.06 16.96 8.43 15.75 9.24ZM10.67 11.5H11.75V5.5C11.75 4.67 12.42 4 13.25 4C14.08 4 14.75 4.67 14.75 5.5V16.24L18.79 15.39L20 16.62L14.62 22H5.95L4.88 14.38L10.67 11.5Z",
      fill: "white"
    })
  },
  'open-in-full': {
    path: _react.default.createElement("path", {
      d: "M21 11V3H13L16.29 6.29L6.29 16.29L3 13V21H11L7.71 17.71L17.71 7.71L21 11Z",
      fill: "#323232"
    })
  },
  'close-fullscreen': {
    path: _react.default.createElement("path", {
      d: "M22 3.41L16.71 8.7L20 12H12V4L15.29 7.29L20.59 2L22 3.41ZM3.41 22L8.7 16.71L12 20V12H4L7.29 15.29L2 20.59L3.41 22Z",
      fill: "#323232"
    })
  },
  'spt-account-balance-wallet': {
    path: _react.default.createElement("path", {
      d: "M10 16V8C10 6.9 10.89 6 12 6H21V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V18H12C10.89 18 10 17.1 10 16ZM13 8C12.45 8 12 8.45 12 9V15C12 15.55 12.45 16 13 16H22V8H13ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z",
      fill: "currentColor"
    })
  },
  'spt-watch-later': {
    path: _react.default.createElement("path", {
      d: "M12.6667 2C7.16675 2 2.66675 6.5 2.66675 12C2.66675 17.5 7.16675 22 12.6667 22C18.1667 22 22.6667 17.5 22.6667 12C22.6667 6.5 18.1667 2 12.6667 2ZM16.2167 15.8L12.1367 13.29C11.8367 13.11 11.6567 12.79 11.6567 12.44V7.75C11.6667 7.34 12.0067 7 12.4167 7C12.8267 7 13.1667 7.34 13.1667 7.75V12.2L17.0067 14.51C17.3667 14.73 17.4867 15.2 17.2667 15.56C17.0467 15.91 16.5767 16.02 16.2167 15.8Z",
      fill: "currentColor"
    })
  },
  'spt-payment': {
    path: _react.default.createElement("path", {
      d: "M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13ZM23 7V18C23 19.1 22.1 20 21 20H4V18H21V7H23Z",
      fill: "currentColor"
    })
  },
  'spt-assignment-person': {
    path: _react.default.createElement("path", {
      d: "M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM12 7C13.66 7 15 8.34 15 10C15 11.66 13.66 13 12 13C10.34 13 9 11.66 9 10C9 8.34 10.34 7 12 7ZM18 19H6V17.6C6 15.6 10 14.5 12 14.5C14 14.5 18 15.6 18 17.6V19Z",
      fill: "currentColor"
    })
  },
  'spt-assignment': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"
    })
  },
  'spt-telephone': {
    path: _react.default.createElement("path", {
      d: "M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z",
      fill: "currentColor"
    })
  },
  'spt-agenda': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17,12h-5v5h5V12z M16,1v2H8V1H6v2H5C3.9,3,3,3.9,3,5l0,14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-1V1H16z M19,19H5V8h14V19z"
    })
  },
  'spt-arrow-down': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,12l-1.4-1.4L13,16.2V4h-2v12.2l-5.6-5.6L4,12l8,8L20,12z"
    })
  },
  'spt-arrow-left': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,11H7.8l5.6-5.6L12,4l-8,8l8,8l1.4-1.4L7.8,13H20V11z"
    })
  },
  'spt-arrow-right': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4l-1.4,1.4l5.6,5.6H4v2h12.2l-5.6,5.6L12,20l8-8L12,4z"
    })
  },
  'spt-arrow-up': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,12l1.4,1.4L11,7.8V20h2V7.8l5.6,5.6L20,12l-8-8L4,12z"
    })
  },
  'spt-bank': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,10v7h3v-7H4z M10,10v7h3v-7H10z M2,22h19v-3H2V22z M16,10v7h3v-7H16z M11.5,1L2,6v2h19V6L11.5,1z"
    })
  },
  'spt-bookmark': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17,3H7C5.9,3,5,3.9,5,5l0,16l7-3l7,3V5C19,3.9,18.1,3,17,3z M17,18l-5-2.2L7,18V5h10V18z"
    })
  },
  'spt-camera': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "12",
      cy: "12",
      r: "3.2"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M9,2L7.2,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6c0-1.1-0.9-2-2-2h-3.2L15,2H9z M12,17 c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17z"
    }))
  },
  'spt-caret-down': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7.4,8.6l4.6,4.6l4.6-4.6L18,10l-6,6l-6-6L7.4,8.6z"
    })
  },
  'spt-caret-left': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.4,16.6L10.8,12l4.6-4.6L14,6l-6,6l6,6L15.4,16.6z"
    })
  },
  'spt-caret-right': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8.6,16.6l4.6-4.6L8.6,7.4L10,6l6,6l-6,6L8.6,16.6z"
    })
  },
  'spt-caret-up': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7.4,15.4l4.6-4.6l4.6,4.6L18,14l-6-6l-6,6L7.4,15.4z"
    })
  },
  'spt-chart': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M9,17H7v-7h2V17z M13,17h-2V7h2V17z M17,17h-2v-4h2V17z"
    })
  },
  'spt-checkbox-off': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z"
    })
  },
  'spt-checkbox-on': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M10,17l-5-5l1.4-1.4l3.6,3.6l7.6-7.6 L19,8L10,17z"
    })
  },
  'spt-chevron-down': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7,10l5,5l5-5H7z"
    })
  },
  'spt-chevron-left': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14,7l-5,5l5,5V7z"
    })
  },
  'spt-chevron-right': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10,17l5-5l-5-5V17z"
    })
  },
  'spt-chevron-up': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7,14l5-5l5,5H7z"
    })
  },
  'spt-close': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,6.4L17.6,5L12,10.6L6.4,5L5,6.4l5.6,5.6L5,17.6L6.4,19l5.6-5.6l5.6,5.6l1.4-1.4L13.4,12L19,6.4z"
    })
  },
  'spt-contacts': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,0H4v2h16V0z M4,24h16v-2H4V24z M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M12,6.8c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2s-2.2-1-2.2-2.2S10.8,6.8,12,6.8z M17,17H7v-1.5c0-1.7,3.3-2.5,5-2.5s5,0.8,5,2.5V17z"
    })
  },
  'spt-dashboard': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3,13h8V3H3V13z M3,21h8v-6H3V21z M13,21h8V11h-8V21z M13,3v6h8V3H13z"
    })
  },
  'spt-delete': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6,19c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V7H6V19z M19,4h-3.5l-1-1h-5l-1,1H5v2h14V4z"
    })
  },
  'spt-done': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M9,16.2L4.8,12l-1.4,1.4L9,19L21,7l-1.4-1.4L9,16.2z"
    })
  },
  'spt-filter': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10,18h4v-2h-4V18z M3,6v2h18V6H3z M6,13h12v-2H6V13z"
    })
  },
  'spt-folder-special': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,6h-8l-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8C22,6.9,21.1,6,20,6z M17.9,17L15,15.3L12.1,17 l0.8-3.3l-2.6-2.2l3.4-0.3L15,8l1.3,3.1l3.4,0.3l-2.6,2.2L17.9,17z"
    })
  },
  'spt-form': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,5v14H5V5H19 M20.1,3H3.9C3.4,3,3,3.4,3,3.9v16.2C3,20.5,3.4,21,3.9,21h16.2c0.4,0,0.9-0.5,0.9-0.9V3.9 C21,3.4,20.5,3,20.1,3z M11,7h6v2h-6V7z M11,11h6v2h-6V11z M11,15h6v2h-6V15z M7,7h2v2H7V7z M7,11h2v2H7V11z M7,15h2v2H7V15z"
    })
  },
  'spt-home': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10,20v-6h4v6h5v-8h3L12,3L2,12h3v8H10z"
    })
  },
  'spt-launch': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,19H5V5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-7h-2V19z M14,3v2h3.6l-9.8,9.8l1.4,1.4L19,6.4V10 h2V3H14z"
    })
  },
  'spt-menu': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3,18h18v-2H3V18z M3,13h18v-2H3V13z M3,6v2h18V6H3z"
    })
  },
  'spt-note': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6,2C4.9,2,4,2.9,4,4l0,16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8l-6-6H6z M13,9V3.5L18.5,9H13z"
    })
  },
  'spt-notification': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,22c1.1,0,2-0.9,2-2h-4C10,21.1,10.9,22,12,22z M18,16v-5c0-3.1-1.6-5.6-4.5-6.3V4c0-0.8-0.7-1.5-1.5-1.5 S10.5,3.2,10.5,4v0.7C7.6,5.4,6,7.9,6,11v5l-2,2v1h16v-1L18,16z"
    })
  },
  'spt-options': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,8c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S10.9,8,12,8z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M12,16c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,16,12,16z"
    })
  },
  'spt-options-h': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
    })
  },
  'spt-person': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5z M15,9c0,1.7-1.3,3-3,3s-3-1.3-3-3 s1.3-3,3-3S15,7.3,15,9z M6,17c0-2,4-3.1,6-3.1s6,1.1,6,3.1v1H6V17z"
    })
  },
  'spt-plus': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6V13z"
    })
  },
  'spt-search': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.5,14h-0.8l-0.3-0.3c1-1.1,1.6-2.6,1.6-4.2C16,5.9,13.1,3,9.5,3S3,5.9,3,9.5S5.9,16,9.5,16c1.6,0,3.1-0.6,4.2-1.6 l0.3,0.3v0.8l5,5l1.5-1.5L15.5,14z M9.5,14C7,14,5,12,5,9.5S7,5,9.5,5S14,7,14,9.5S12,14,9.5,14z"
    })
  },
  'spt-sms-failed': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,2H4C2.9,2,2,2.9,2,4l0,18l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M13,14h-2v-2h2V14z M13,10h-2V6h2V10z"
    })
  },
  'spt-star': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,17.3l6.2,3.7l-1.6-7L22,9.2l-7.2-0.6L12,2L9.2,8.6L2,9.2L7.5,14l-1.6,7L12,17.3z"
    })
  },
  'spt-store': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,4H4v2h16V4z M21,14v-2l-1-5H4l-1,5v2h1v6h10v-6h4v6h2v-6H21z M12,18H6v-4h6V18z"
    })
  },
  'spt-view-grid': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,11h5V5H4V11z M4,18h5v-6H4V18z M10,18h5v-6h-5V18z M16,18h5v-6h-5V18z M10,11h5V5h-5V11z M16,5v6h5V5H16z"
    })
  },
  'spt-view-list': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,14h4v-4H4V14z M4,19h4v-4H4V19z M4,9h4V5H4V9z M9,14h12v-4H9V14z M9,19h12v-4H9V19z M9,5v4h12V5H9z"
    })
  },
  'spt-work': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8l0,11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M14,6h-4V4h4V6z"
    })
  },
  'spt-account-minus': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M1,10V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
    })
  },
  'spt-account-plus': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
    })
  },
  'spt-apps': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.5,11H19V7c0-1.1-0.9-2-2-2h-4V3.5C13,2.1,11.9,1,10.5,1S8,2.1,8,3.5V5H4C2.9,5,2,5.9,2,7v3.8h1.5c1.5,0,2.7,1.2,2.7,2.7  S5,16.2,3.5,16.2H2V20c0,1.1,0.9,2,2,2h3.8v-1.5c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7V22H17c1.1,0,2-0.9,2-2v-4h1.5  c1.4,0,2.5-1.1,2.5-2.5S21.9,11,20.5,11z"
    })
  },
  'spt-arrow-down-alt': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13,16V4h-2v12H8l4,4l4-4H13z"
    })
  },
  'spt-arrow-up-alt': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11,8v12h2V8h3l-4-4L8,8H11z"
    })
  },
  'spt-chat': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21,6h-2v9H6v2c0,0.5,0.4,1,1,1h11l4,4V7C22,6.4,21.5,6,21,6z M17,12V3c0-0.5-0.5-1-1-1H3C2.5,2,2,2.5,2,3v14l4-4h10  C16.5,13,17,12.6,17,12z"
    })
  },
  'spt-chat-simple': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"
    })
  },
  'spt-check-circle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M10,17l-5-5l1.4-1.4l3.6,3.6l7.6-7.6L19,8L10,17z"
    })
  },
  'spt-close-circle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17,15.6L15.6,17L12,13.4L8.4,17L7,15.6l3.6-3.6L7,8.4  L8.4,7l3.6,3.6L15.6,7L17,8.4L13.4,12L17,15.6z"
    })
  },
  'spt-comment': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"
    })
  },
  'spt-drag': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11,18c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S11,16.9,11,18z M9,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S10.1,10,9,10z   M9,4C7.9,4,7,4.9,7,6s0.9,2,2,2s2-0.9,2-2S10.1,4,9,4z M15,8c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S13.9,8,15,8z M15,10  c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.1,10,15,10z M15,16c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.1,16,15,16z"
    })
  },
  'spt-drag-handle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,9H4v2h16V9z M4,15h16v-2H4V15z"
    })
  },
  'spt-drag-horizontal': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6,11c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S7.1,11,6,11z M14,9c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S14,10.1,14,9z M20,9  c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S20,10.1,20,9z M16,15c0,1.1,0.9,2,2,2s2-0.9,2-2s-0.9-2-2-2S16,13.9,16,15z M14,15  c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S14,16.1,14,15z M8,15c0-1.1-0.9-2-2-2s-2,0.9-2,2s0.9,2,2,2S8,16.1,8,15z"
    })
  },
  'spt-edit': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3,17.2V21h3.8L17.8,9.9l-3.8-3.8L3,17.2z M20.7,7c0.4-0.4,0.4-1,0-1.4l-2.3-2.3c-0.4-0.4-1-0.4-1.4,0l-1.8,1.8l3.8,3.8  C18.9,8.9,20.7,7,20.7,7z"
    })
  },
  'spt-emoji-happy': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8 S16.4,20,12,20z M15.5,11c0.8,0,1.5-0.7,1.5-1.5S16.3,8,15.5,8S14,8.7,14,9.5S14.7,11,15.5,11z M8.5,11c0.8,0,1.5-0.7,1.5-1.5  S9.3,8,8.5,8S7,8.7,7,9.5S7.7,11,8.5,11z M12,17.5c2.3,0,4.3-1.5,5.1-3.5H6.9C7.7,16,9.7,17.5,12,17.5z"
    })
  },
  'spt-emoji-love': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7.8,9.9l0.7,0.7l0.7-0.7l0.7-0.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4   L7.8,9.9z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,17.5c2.3,0,4.3-1.4,5.1-3.5H6.9C7.7,16.1,9.7,17.5,12,17.5z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8   S16.4,20,12,20z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.5,7.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0.7,0.7l0.7,0.7l0.7-0.7l0.7-0.7c0.4-0.4,0.4-1,0-1.4   C16.5,7.4,15.9,7.4,15.5,7.8z"
    }))
  },
  'spt-emoji-neutral': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2 M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20   M8.5,11C7.7,11,7,10.3,7,9.5S7.7,8,8.5,8S10,8.7,10,9.5S9.3,11,8.5,11 M17,9.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5  S14.7,8,15.5,8C16.3,8,17,8.7,17,9.5 M16,14v2H8v-2H16z"
    })
  },
  'spt-emoji-sad': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10,9.5C10,8.7,9.3,8,8.5,8S7,8.7,7,9.5S7.7,11,8.5,11S10,10.3,10,9.5z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,14c-1.6,0-3.1,0.6-4.2,1.8l1.4,1.4C9.7,16.5,10.8,16,12,16s2.3,0.5,2.8,1.2l1.4-1.4C15.1,14.6,13.6,14,12,14z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.5,8C14.7,8,14,8.7,14,9.5s0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5S16.3,8,15.5,8z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8   S16.4,20,12,20z"
    }))
  },
  'spt-emoji-smile': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,12c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8S20,16.4,20,12 M22,12c0,5.5-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2S22,6.5,22,12   M10,9.5c0,0.8-0.7,1.5-1.5,1.5S7,10.3,7,9.5S7.7,8,8.5,8S10,8.7,10,9.5 M17,9.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5  S14.7,8,15.5,8C16.3,8,17,8.7,17,9.5 M12,17.2c-1.8,0-3.3-0.7-4.2-1.8L9.2,14c0.5,0.7,1.5,1.2,2.8,1.2s2.3-0.5,2.8-1.2l1.4,1.4  C15.3,16.5,13.8,17.2,12,17.2z"
    })
  },
  'spt-help': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,19h-2v-2h2V19z M15.1,11.2l-0.9,0.9  C13.4,12.9,13,13.5,13,15h-2v-0.5c0-1.1,0.4-2.1,1.2-2.8l1.2-1.3C13.8,10.1,14,9.6,14,9c0-1.1-0.9-2-2-2s-2,0.9-2,2H8  c0-2.2,1.8-4,4-4s4,1.8,4,4C16,9.9,15.6,10.7,15.1,11.2z"
    })
  },
  'spt-info': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z"
    })
  },
  'spt-linear-scale': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19.5,9.5c-1,0-1.9,0.6-2.3,1.5h-2.9C13.9,10.1,13,9.5,12,9.5s-1.9,0.6-2.3,1.5H6.8c-0.4-0.9-1.3-1.5-2.3-1.5  C3.1,9.5,2,10.6,2,12s1.1,2.5,2.5,2.5c1,0,1.9-0.6,2.3-1.5h2.9c0.4,0.9,1.3,1.5,2.3,1.5s1.9-0.6,2.3-1.5h2.9  c0.4,0.9,1.3,1.5,2.3,1.5c1.4,0,2.5-1.1,2.5-2.5S20.9,9.5,19.5,9.5z"
    })
  },
  'spt-lock': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M18,8h-1V6c0-2.8-2.2-5-5-5S7,3.2,7,6v2H6c-1.1,0-2,0.9-2,2v10c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V10C20,8.9,19.1,8,18,8z   M12,17c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S13.1,17,12,17z M15.1,8H8.9V6c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1V8z"
    })
  },
  'spt-mail': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,4H4C2.9,4,2,4.9,2,6l0,12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,8l-8,5L4,8V6l8,5l8-5V8z"
    })
  },
  'spt-minus': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,13H5v-2h14V13z"
    })
  },
  'spt-phone': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.5,1h-8C6.1,1,5,2.1,5,3.5v17C5,21.9,6.1,23,7.5,23h8c1.4,0,2.5-1.1,2.5-2.5v-17C18,2.1,16.9,1,15.5,1z M11.5,22  c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S12.3,22,11.5,22z M16,18H7V4h9V18z"
    })
  },
  'spt-pin': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C8.1,2,5,5.1,5,9c0,5.2,7,13,7,13s7-7.8,7-13C19,5.1,15.9,2,12,2z M12,12c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3s3,1.3,3,3  C15,10.7,13.7,12,12,12z"
    })
  },
  'spt-print': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,8H5c-1.7,0-3,1.3-3,3v6h4v4h12v-4h4v-6C22,9.3,20.7,8,19,8z M16,19H8v-5h8V19z M19,12c-0.5,0-1-0.4-1-1s0.5-1,1-1  s1,0.4,1,1S19.5,12,19,12z M18,3H6v4h12V3z"
    })
  },
  'spt-time': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    }), "), (", _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
    }))
  },
  'spt-radio-button-off': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20  z"
    })
  },
  'spt-radio-button-on': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,7c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S14.8,7,12,7z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z   M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z"
    })
  },
  'spt-school': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M5,13.2v4l7,3.8l7-3.8v-4L12,17L5,13.2z M12,3L1,9l11,6l9-4.9V17h2V9L12,3z"
    })
  },
  'spt-special-01': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "11.9",
      cy: "5.6",
      r: "1.6"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13.3,8.6c-0.1-0.2-0.3-0.3-0.5-0.4l0,0l0,0c-0.3-0.2-0.6-0.2-1-0.2c-0.8,0.1-1.4,0.8-1.4,1.7v4.7c0,0.9,0.7,1.6,1.6,1.6h4   v4h1.6v-4.4c0-0.9-0.7-1.6-1.6-1.6h-2.4v-2.7c1.1,1,2.5,1.5,4,1.6v-1.6c-1.3,0-2.4-0.5-3.2-1.4L13.3,8.6z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M9.6,18.2c-1.3-0.4-1.9-1.8-1.4-3c0.2-0.7,0.8-1.2,1.4-1.4v-1.7c-2.1,0.4-3.5,2.6-3.1,4.7c0.4,2.1,2.6,3.5,4.7,3.1   c1.6-0.3,2.8-1.6,3.1-3.1h-1.7C12.2,18,10.8,18.7,9.6,18.2z"
    }))
  },
  'spt-special-02': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M 12 0 C 5.398438 0 0 5.398438 0 12 C 0 18.601562 5.398438 24 12 24 C 18.601562 24 24 18.601562 24 12 C 24 5.398438 18.601562 0 12 0 Z M 12 23 C 5.949219 23 1 18.050781 1 12 C 1 5.949219 5.949219 1 12 1 C 18.050781 1 23 5.949219 23 12 C 23 18.050781 18.050781 23 12 23 Z M 12 23 "
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M 11.648438 7.648438 C 12.398438 7.648438 13 7.050781 13 6.300781 C 13 5.550781 12.398438 4.949219 11.648438 4.949219 C 10.898438 4.949219 10.300781 5.550781 10.300781 6.300781 C 10.351562 7.050781 10.949219 7.648438 11.648438 7.648438 Z M 11.648438 7.648438 "
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M 12.449219 9.75 L 11.800781 8.699219 C 11.550781 8.300781 11.148438 8.050781 10.648438 8.050781 C 10.449219 8.050781 10.300781 8.101562 10.101562 8.101562 L 6.648438 9.550781 L 6.648438 12.699219 L 8 12.699219 L 8 10.449219 L 9.199219 10 L 7.351562 19.398438 L 8.75 19.398438 L 9.949219 14 L 11.351562 15.351562 L 11.351562 19.351562 L 12.699219 19.351562 L 12.699219 14.351562 L 11.25 13 L 11.648438 11 C 12.601562 12.050781 13.898438 12.648438 15.300781 12.648438 L 15.300781 11.300781 C 14.148438 11.351562 13.050781 10.75 12.449219 9.75 Z M 12.449219 9.75 "
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M 16.648438 11.351562 L 16 11.351562 L 16 12.699219 L 16.648438 12.699219 L 16.648438 19.351562 L 18 19.351562 L 18 12.648438 C 18 11.949219 17.398438 11.351562 16.648438 11.351562 Z M 16.648438 11.351562 "
    }))
  },
  'spt-special-03': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "12",
      cy: "5.5",
      r: "1.5"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14.6,11.3l0.5-0.5c0.2-0.1,0.2-0.3,0.2-0.5V9.2c0-0.8-0.7-1.5-1.5-1.5l0,0h-3.6c-0.8,0-1.5,0.7-1.5,1.5l0,0v1.1   c0,0.2,0.1,0.4,0.2,0.5l0.5,0.5l-1.1,3.4c-0.1,0.4,0.1,0.8,0.4,0.9c0.1,0,0.2,0.1,0.2,0.1h0.9V20h1.5v-4.4h1.5V20h1.4v-4.4H15   c0.4,0,0.7-0.3,0.7-0.7c0-0.1,0-0.2-0.1-0.2L14.6,11.3z M13.7,10.4L13.7,10.4l-1,1c-0.4,0.4-1.1,0.4-1.5,0l0,0   c-0.4-0.4-0.4-1.1,0-1.5l0,0l1-1c0.4-0.4,1.1-0.4,1.5,0l0,0C14.1,9.3,14.1,10,13.7,10.4z"
    }))
  },
  'spt-special-04': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11.2,7.2c0.9,0,1.6-0.7,1.6-1.6s-0.7-1.6-1.6-1.6S9.6,4.7,9.6,5.6l0,0C9.6,6.5,10.4,7.2,11.2,7.2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13.6,10.4C13.6,9,12.6,8,11.2,8C9.9,8,8.9,9,8.9,10.4V16h1.6v4h2.4v-4h2.4v-3.2C15.2,11.8,14.6,10.8,13.6,10.4z"
    }))
  },
  'spt-special-05': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4.8,9.6h4v4c0,0.6,0.3,1.1,0.8,1.4v5h1.6v-4.8h1.6V20h1.6v-5c0.5-0.3,0.8-0.8,0.8-1.4v-4h4V8H4.8V9.6z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,7.2c0.9,0,1.6-0.7,1.6-1.6S12.9,4,12,4s-1.6,0.7-1.6,1.6S11.1,7.2,12,7.2z"
    }))
  },
  'spt-star-outline': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M22,9.2l-7.2-0.6L12,2L9.2,8.6L2,9.2L7.5,14l-1.6,7l6.2-3.7l6.2,3.7l-1.6-7L22,9.2z M12,15.4l-3.8,2.3l1-4.3l-3.3-2.9  l4.4-0.4l1.7-4l1.7,4l4.4,0.4l-3.3,2.9l1,4.3L12,15.4z"
    })
  },
  'spt-swap-horizontal-circle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-7-5.5l3.5 3.5-3.5 3.5V11h-4V9h4V6.5zm-6 11L5.5 14 9 10.5V13h4v2H9v2.5z"
    })
  },
  'spt-swap-vert': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M16,17v-7h-2v7h-3l4,4l4-4H16z M9,3L5,7h3v7h2V7h3L9,3z"
    })
  },
  'spt-tag': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21.4,11.6l-9-9C12.1,2.2,11.6,2,11,2H4C2.9,2,2,2.9,2,4v7c0,0.6,0.2,1.1,0.6,1.4l9,9c0.4,0.4,0.9,0.6,1.4,0.6  s1.1-0.2,1.4-0.6l7-7c0.4-0.4,0.6-0.9,0.6-1.4S21.8,11.9,21.4,11.6z M5.5,7C4.7,7,4,6.3,4,5.5S4.7,4,5.5,4S7,4.7,7,5.5S6.3,7,5.5,7z  "
    })
  },
  'spt-text-long': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3,18h12v-2H3V18z M3,6v2h18V6H3z M3,13h18v-2H3V13z"
    })
  },
  'spt-text-short': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,9h16v2H4V9z M4,13h10v2H4V13z"
    })
  },
  'spt-thumb-up': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M1,21h4V9H1V21z M23,10c0-1.1-0.9-2-2-2h-6.3l0.9-4.6l0-0.3c0-0.4-0.2-0.8-0.4-1.1l-1.1-1L7.6,7.6C7.2,7.9,7,8.4,7,9v10  c0,1.1,0.9,2,2,2h9c0.8,0,1.5-0.5,1.8-1.2l3-7.1C23,12.5,23,12.3,23,12V10z"
    })
  },
  'spt-toggle-number-00': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      d: "M12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z",
      fill: "currentColor"
    }), _react.default.createElement("path", {
      d: "M12 15.24C11.42 15.24 10.9033 15.0967 10.45 14.81C9.99667 14.5233 9.64 14.11 9.38 13.57C9.12667 13.0233 9 12.3733 9 11.62C9 10.8667 9.12667 10.22 9.38 9.68C9.64 9.13333 9.99667 8.71667 10.45 8.43C10.9033 8.14333 11.42 8 12 8C12.58 8 13.0967 8.14333 13.55 8.43C14.0033 8.71667 14.3567 9.13333 14.61 9.68C14.87 10.22 15 10.8667 15 11.62C15 12.3733 14.87 13.0233 14.61 13.57C14.3567 14.11 14.0033 14.5233 13.55 14.81C13.0967 15.0967 12.58 15.24 12 15.24ZM12 13.87C12.4267 13.87 12.76 13.6867 13 13.32C13.2467 12.9533 13.37 12.3867 13.37 11.62C13.37 10.8533 13.2467 10.2867 13 9.92C12.76 9.55333 12.4267 9.37 12 9.37C11.58 9.37 11.2467 9.55333 11 9.92C10.76 10.2867 10.64 10.8533 10.64 11.62C10.64 12.3867 10.76 12.9533 11 13.32C11.2467 13.6867 11.58 13.87 12 13.87Z",
      fill: "currentColor"
    }))
  },
  'spt-toggle-number-01': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "10.1,7.9 10.1,9.2 11.5,9.2 11.5,14.9 13.1,14.9 13.1,7.9 "
    }))
  },
  'spt-toggle-number-02': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11.7,13.6l1.6-1.5c1-0.9,1.2-1.5,1.2-2.2c0-1.3-1-2.1-2.6-2.1c-1.3,0-2.2,0.5-2.8,1.3l1.2,0.8c0.3-0.5,0.8-0.7,1.4-0.7  c0.7,0,1.1,0.3,1.1,0.9c0,0.3-0.1,0.7-0.7,1.3l-2.7,2.5v1h5.3v-1.3H11.7z"
    }))
  },
  'spt-toggle-number-03': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12.7,10.8L14.3,9v-1H9.4v1.3h3l-1.4,1.6v1.1h0.7c0.9,0,1.3,0.3,1.3,0.9c0,0.6-0.5,0.9-1.3,0.9c-0.7,0-1.5-0.2-2-0.6L9,14.3  c0.7,0.5,1.7,0.8,2.7,0.8c2,0,2.9-1.1,2.9-2.3C14.6,11.8,14,11,12.7,10.8z"
    }))
  },
  'spt-toggle-number-04': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "14.2,12.1 14.2,10.8 12.7,10.8 12.7,12.1 10.8,12.1 13.9,7.9 12.2,7.9 8.9,12.4 8.9,13.5 12.6,13.5 12.6,14.9   14.2,14.9 14.2,13.5 15.4,13.5 15.4,12.1 "
    }))
  },
  'spt-toggle-number-05': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11.8,10.6h-0.6l0.1-1.3h2.9V7.9H10l-0.4,3.9h1.8c1.3,0,1.6,0.4,1.6,0.9c0,0.6-0.5,0.9-1.3,0.9c-0.7,0-1.5-0.2-2-0.6  l-0.6,1.2c0.7,0.5,1.7,0.8,2.7,0.8c2,0,2.9-1.1,2.9-2.3S13.9,10.6,11.8,10.6z"
    }))
  },
  'spt-toggle-number-06': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12.6,10.5c-0.7,0-1.3,0.2-1.7,0.6c0.1-1.3,0.8-2,2-2c0.5,0,0.9,0.1,1.3,0.3l0.6-1.2c-0.5-0.3-1.2-0.5-1.9-0.5  c-2.1,0-3.6,1.3-3.6,3.8c0,2.3,1.2,3.5,3.1,3.5c1.5,0,2.6-0.9,2.6-2.3C15,11.4,13.9,10.5,12.6,10.5z M12.2,13.8  c-0.8,0-1.2-0.4-1.2-1c0-0.6,0.5-1,1.2-1c0.7,0,1.2,0.4,1.2,1S12.9,13.8,12.2,13.8z"
    }))
  },
  'spt-toggle-number-07': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14.8,7.9v1l-2.6,5.9h-1.8l2.5-5.7h-2.3v1.1H9.2V7.9H14.8z"
    }))
  },
  'spt-toggle-number-08': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14.9,12.9c0,1.3-1.2,2.1-3,2.1C10.2,15,9,14.2,9,12.9c0-0.8,0.4-1.3,1.1-1.6c-0.5-0.3-0.8-0.8-0.8-1.4c0-1.2,1.1-2,2.7-2   c1.6,0,2.7,0.8,2.7,2c0,0.6-0.3,1.1-0.8,1.4C14.6,11.6,14.9,12.1,14.9,12.9z M13.3,12.8c0-0.6-0.5-1-1.3-1c-0.8,0-1.3,0.4-1.3,1   c0,0.6,0.5,1,1.3,1C12.8,13.9,13.3,13.5,13.3,12.8z M10.9,9.9c0,0.5,0.4,0.9,1.1,0.9c0.7,0,1.1-0.3,1.1-0.9C13.1,9.3,12.6,9,12,9   C11.3,9,10.9,9.3,10.9,9.9z"
    }))
  },
  'spt-toggle-number-09': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14.8,11.3c0,2.4-1.4,3.8-3.6,3.8c-0.7,0-1.4-0.2-1.9-0.5l0.6-1.2c0.4,0.3,0.8,0.3,1.3,0.3c1.2,0,1.9-0.7,2-2   c-0.4,0.4-1,0.6-1.7,0.6c-1.3,0-2.4-0.9-2.4-2.2c0-1.4,1.2-2.3,2.6-2.3C13.6,7.8,14.8,9,14.8,11.3z M13,10.1c0-0.6-0.5-1-1.2-1   c-0.7,0-1.2,0.4-1.2,1s0.5,1,1.2,1C12.5,11.1,13,10.7,13,10.1z"
    }))
  },
  'spt-toggle-number-10': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S7.6,4,12,4 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2   L12,2z"
    }), _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "6.7,9.2 8.1,9.2 8.1,14.9 9.7,14.9 9.7,7.9 6.7,7.9  "
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14,7.8c-1.7,0-3,1.3-3,3.6s1.3,3.6,3,3.6c1.7,0,3-1.3,3-3.6S15.7,7.8,14,7.8z M14,13.7c-0.8,0-1.4-0.7-1.4-2.2   s0.6-2.2,1.4-2.2c0.8,0,1.4,0.7,1.4,2.2S14.8,13.7,14,13.7z"
    }))
  },
  'spt-unarchive': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.5,5.2l-1.4-1.7C18.9,3.2,18.5,3,18,3H6C5.5,3,5.1,3.2,4.8,3.5L3.5,5.2C3.2,5.6,3,6,3,6.5V19c0,1.1,0.9,2,2,2h14  c1.1,0,2-0.9,2-2V6.5C21,6,20.8,5.6,20.5,5.2z M14,15v2h-4v-2H6.5L12,9.5l5.5,5.5H14z M5.1,5l0.8-1h12l0.9,1H5.1z"
    })
  },
  'spt-user': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,12c2.2,0,4-1.8,4-4s-1.8-4-4-4S8,5.8,8,8S9.8,12,12,12z M12,14c-2.7,0-8,1.3-8,4v2h16v-2C20,15.3,14.7,14,12,14z"
    })
  },
  'spt-visibility': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,4.5C7,4.5,2.7,7.6,1,12c1.7,4.4,6,7.5,11,7.5s9.3-3.1,11-7.5C21.3,7.6,17,4.5,12,4.5z M12,17c-2.8,0-5-2.2-5-5  s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S13.7,9,12,9z"
    })
  },
  'spt-visibility-off': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,7c2.8,0,5,2.2,5,5c0,0.6-0.1,1.3-0.4,1.8l2.9,2.9c1.5-1.3,2.7-2.9,3.4-4.8c-1.7-4.4-6-7.5-11-7.5c-1.4,0-2.7,0.2-4,0.7  l2.2,2.2C10.7,7.1,11.4,7,12,7z M2,4.3l2.3,2.3L4.7,7c-1.7,1.3-3,3-3.7,5c1.7,4.4,6,7.5,11,7.5c1.6,0,3-0.3,4.4-0.8l0.4,0.4l2.9,2.9  l1.3-1.3L3.3,3L2,4.3z M7.5,9.8l1.5,1.6C9,11.6,9,11.8,9,12c0,1.7,1.3,3,3,3c0.2,0,0.4,0,0.6-0.1l1.6,1.5C13.5,16.8,12.8,17,12,17  c-2.8,0-5-2.2-5-5C7,11.2,7.2,10.5,7.5,9.8z M11.8,9l3.1,3.1l0-0.2c0-1.7-1.3-3-3-3L11.8,9z"
    })
  },
  'spt-warning': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M1,21h22L12,2L1,21z M13,18h-2v-2h2V18z M13,14h-2v-4h2V14z"
    })
  },
  'spt-alert': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z"
    })
  },
  'spt-cloud-upload': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19.4,10c-0.7-3.4-3.7-6-7.4-6C9.1,4,6.6,5.6,5.3,8C2.3,8.4,0,10.9,0,14c0,3.3,2.7,6,6,6h13c2.8,0,5-2.2,5-5  C24,12.4,22,10.2,19.4,10z M14,13v4h-4v-4H7l5-5l5,5H14z"
    })
  },
  'spt-user-group': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M16,11c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S14.3,11,16,11z M8,11c1.7,0,3-1.3,3-3S9.7,5,8,5S5,6.3,5,8S6.3,11,8,11z   M8,13c-2.3,0-7,1.2-7,3.5V19h14v-2.5C15,14.2,10.3,13,8,13z M16,13c-0.3,0-0.6,0-1,0.1c1.2,0.8,2,2,2,3.4V19h6v-2.5  C23,14.2,18.3,13,16,13z"
    })
  },
  'spt-account-circle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
    })
  },
  'spt-people-outline': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M16.5 13c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1C5.33 13 1 14.08 1 16.25V19h22v-2.75c0-2.17-4.33-3.25-6.5-3.25zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0H14v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zM7.5 12c1.93 0 3.5-1.57 3.5-3.5S9.43 5 7.5 5 4 6.57 4 8.5 5.57 12 7.5 12zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5c1.93 0 3.5-1.57 3.5-3.5S18.43 5 16.5 5 13 6.57 13 8.5s1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
    })
  },
  'spt-select-all': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3 5h2V3c-1.1 0-2 .9-2 2zm0 8h2v-2H3v2zm4 8h2v-2H7v2zM3 9h2V7H3v2zm10-6h-2v2h2V3zm6 0v2h2c0-1.1-.9-2-2-2zM5 21v-2H3c0 1.1.9 2 2 2zm-2-4h2v-2H3v2zM9 3H7v2h2V3zm2 18h2v-2h-2v2zm8-8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2zm0-12h2V7h-2v2zm0 8h2v-2h-2v2zm-4 4h2v-2h-2v2zm0-16h2V3h-2v2zM7 17h10V7H7v10zm2-8h6v6H9V9z"
    })
  },
  'spt-view-headline': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"
    })
  },
  'spt-fiber-manual-record': {
    path: _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "12",
      cy: "12",
      r: "8"
    })
  },
  'spt-exit-to-app': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
    })
  },
  'spt-playlist-add-check': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("clipPath", {
      id: "a"
    }, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M0 0h24v24H0V0z"
    })), _react.default.createElement("path", {
      clipPath: "url(#a)",
      fill: "currentColor",
      d: "M14 10H2v2h12v-2zm0-4H2v2h12V6zM2 16h8v-2H2v2zm19.5-4.5L23 13l-6.99 7-4.51-4.5L13 14l3.01 3 5.49-5.5z"
    }))
  },
  'spt-assigment-turned-in': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,3h-4.2c-0.4-1.2-1.5-2-2.8-2S9.6,1.8,9.2,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5 C21,3.9,20.1,3,19,3z M12,3c0.6,0,1,0.5,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,3,12,3z M10,17l-4-4l1.4-1.4l2.6,2.6l6.6-6.6L18,9L10,17z"
    })
  },
  'spt-settings': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
    })
  },
  'spt-send': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M2,21l21-9L2,3l0,7l15,2L2,14L2,21z"
    })
  },
  'spt-query-builder': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"
    }))
  },
  'spt-ballot': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13,9.5h5v-2h-5V9.5z M13,16.5h5v-2h-5V16.5z M19,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2 v14C21,20.1,20.1,21,19,21z M6,11h5V6H6V11z M7,7h3v3H7V7z M6,18h5v-5H6V18z M7,14h3v3H7V14z"
    })
  },
  'spt-note-add': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M14,2H6C4.9,2,4,2.9,4,4l0,16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M16,16h-3v3h-2v-3H8v-2h3v-3h2v3h3V16z M13,9V3.5 L18.5,9H13z"
    })
  },
  'spt-child-friendly': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13 2v8h8c0-4.42-3.58-8-8-8zm6.32 13.89C20.37 14.54 21 12.84 21 11H6.44l-.95-2H2v2h2.22s1.89 4.07 2.12 4.42c-1.1.59-1.84 1.75-1.84 3.08C4.5 20.43 6.07 22 8 22c1.76 0 3.22-1.3 3.46-3h2.08c.24 1.7 1.7 3 3.46 3 1.93 0 3.5-1.57 3.5-3.5 0-1.04-.46-1.97-1.18-2.61zM8 20c-.83 0-1.5-.67-1.5-1.5S7.17 17 8 17s1.5.67 1.5 1.5S8.83 20 8 20zm9 0c-.83 0-1.5-.67-1.5-1.5S16.17 17 17 17s1.5.67 1.5 1.5S17.83 20 17 20z"
    })
  },
  'spt-group-add': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"
    })
  },
  'spt-history': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"
    })
  },
  'spt-list': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z"
    })
  },
  'spt-today': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"
    })
  },
  'spt-share': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,20v-2H5v2H19z M19,10h-4V4H9v6H5l7,7L19,10z"
    })
  },
  'spt-archive': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"
    })
  },
  'spt-cash-multiple': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z"
    })
  },
  'spt-download': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,20v-2H5v2H19z M19,10h-4V4H9v6H5l7,7L19,10z"
    })
  },
  'spt-clinic': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,6.5h-4v-2c0-1.1-0.9-2-2-2h-4c-1.1,0-2,0.9-2,2v2H4c-1.1,0-2,0.9-2,2v11c0,1.1,0.9,2,2,2h16 c1.1,0,2-0.9,2-2v-11C22,7.4,21.1,6.5,20,6.5z M10,4.5h4v2h-4V4.5z M15.5,15H13v2.5h-2V15H8.5v-2H11v-2.5h2V13h2.5V15z"
    })
  },
  'spt-input-small': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.2,4.7H3.8C2.8,4.7,2,5.5,2,6.5v10.9c0,1,0.8,1.8,1.8,1.8h16.4c1,0,1.8-0.8,1.8-1.8V6.5C22,5.5,21.2,4.7,20.2,4.7z M20.2,17.5H3.8V6.5h16.4V17.5z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "4.7",
      y: "7.5",
      width: "4.5",
      height: "9.1"
    }))
  },
  'spt-input-medium': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.2,4.7H3.8C2.8,4.7,2,5.5,2,6.5v10.9c0,1,0.8,1.8,1.8,1.8h16.4c1,0,1.8-0.8,1.8-1.8V6.5C22,5.5,21.2,4.7,20.2,4.7z M20.2,17.5H3.8V6.5h16.4V17.5z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "4.7",
      y: "7.5",
      width: "9.1",
      height: "9.1"
    }))
  },
  'spt-input-large': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20.2,4.7H3.8C2.8,4.7,2,5.5,2,6.5v10.9c0,1,0.8,1.8,1.8,1.8h16.4c1,0,1.8-0.8,1.8-1.8V6.5C22,5.5,21.2,4.7,20.2,4.7z M20.2,17.5H3.8V6.5h16.4V17.5z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "4.7",
      y: "7.5",
      width: "14.5",
      height: "9.1"
    }))
  },
  'spt-check-outline': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "16.6,7.6 10,14.2 6.4,10.6 5,12 10,17 18,9"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8 S16.4,20,12,20z"
    }))
  },
  'spt-timer': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "9",
      y: "1",
      width: "6",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "11",
      y: "8",
      width: "2",
      height: "6"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,7.4L20.5,6C20,5.5,19.5,5,19,4.6L17.6,6c-1.5-1.2-3.5-2-5.6-2c-5,0-9,4-9,9s4,9,9,9s9-4,9-9C21,10.9,20.3,8.9,19,7.4z M12,20c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S15.9,20,12,20z"
    }))
  },
  'spt-chached': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z"
    }))
  },
  'spt-copy-content': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M16.1,2H5.2c-1,0-1.8,0.8-1.8,1.8v12.7h1.8V3.8h10.9V2z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M18.8,5.6L18.8,5.6h-10C7.8,5.6,7,6.5,7,7.5v12.7c0,1,0.8,1.8,1.8,1.8h10c1,0,1.8-0.8,1.8-1.8V7.5 C20.6,6.5,19.8,5.6,18.8,5.6z M18.8,20.2h-10V7.5h10V20.2z"
    }))
  },
  'spt-acessibility': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7h-6v13h-2v-6h-2v6H9V9H3V7h18v2z"
    })
  },
  'spt-temperature': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.4,13.9V5.4c0-1.9-1.5-3.4-3.4-3.4S8.6,3.5,8.6,5.4v8.6c-0.9,0.9-1.3,2.1-1.3,3.3c0,2.6,2.2,4.7,4.8,4.7 c2.6,0,4.7-2.1,4.7-4.7C16.8,16,16.3,14.8,15.4,13.9z M12,20.7L12,20.7c-1.9,0-3.4-1.5-3.4-3.4c0-1.1,0.5-2.1,1.4-2.7V9.1h1.5 c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H9.9V7.1h1.5c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H9.9V5.4c0-1.2,0.9-2.1,2.1-2.1 c1.2,0,2.1,0.9,2.1,2.1c0,0,0,0,0,0v9.2c0.9,0.6,1.3,1.6,1.3,2.7C15.4,19.1,13.9,20.7,12,20.7z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13.1,15.3v-5.2h-2.2v5.2c-0.7,0.4-1.1,1.1-1.1,1.9c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2C14.2,16.4,13.8,15.7,13.1,15.3z"
    }))
  },
  'spt-pause': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "6",
      y: "5",
      width: "4",
      height: "14"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "14",
      y: "5",
      width: "4",
      height: "14"
    }))
  },
  'spt-wind': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M18.6,6c-1.8,0-3.3,1.5-3.3,3.3c0,0,0,0.1,0,0.1c0,0.4,0.4,0.7,0.7,0.6c0.4,0,0.7-0.4,0.6-0.7c0-1.1,0.9-1.9,1.9-1.9 c1.1,0,1.9,0.9,2,1.9c0,1.1-0.9,1.9-1.9,2H2.7c-0.3,0-0.6,0.3-0.6,0.6c0,0.4,0.3,0.7,0.6,0.7h15.9c1.8,0,3.3-1.5,3.3-3.3 C22,7.5,20.5,6,18.6,6z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12.7,14.5h-10c0,0-0.1,0-0.1,0c-0.4,0-0.7,0.4-0.6,0.7c0,0.4,0.4,0.7,0.7,0.6h10c1.1,0,1.9,0.9,1.9,1.9s-0.9,1.9-1.9,1.9 s-1.9-0.9-1.9-1.9c0-0.3-0.3-0.6-0.6-0.6c-0.4,0-0.7,0.3-0.7,0.6c0,1.8,1.5,3.3,3.3,3.3h0c1.8,0,3.3-1.5,3.3-3.3 C16,16,14.5,14.5,12.7,14.5z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M2.7,9.5h6.8c1.8,0,3.3-1.5,3.3-3.3s-1.5-3.3-3.3-3.3S6.1,4.3,6.1,6.2c0,0,0,0.1,0,0.1c0,0.4,0.4,0.7,0.7,0.6 c0.4,0,0.7-0.4,0.6-0.7c0-1.1,0.9-1.9,1.9-1.9s1.9,0.9,1.9,1.9s-0.9,1.9-1.9,1.9H2.7c0,0-0.1,0-0.1,0C2.2,8.1,1.9,8.5,2,8.8 C2,9.2,2.3,9.5,2.7,9.5z"
    }))
  },
  'spt-line-chart': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17.8,12L16,2.8C15.9,2.3,15.5,2,15,2h0c-0.5,0-0.9,0.3-1,0.8l-3,13.5L9,6.8C8.9,6.3,8.5,6,8,6C7.6,6,7.1,6.3,7,6.7 l-1.8,6.6L5,11.8C4.9,11.3,4.5,11,4,11H2v2h1.2L4,18.2C4.1,18.6,4.5,19,4.9,19c0.5,0,0.9-0.3,1-0.7l1.9-7l2.1,10 c0.1,0.5,0.5,0.8,1,0.8c0.5,0,0.9-0.3,1-0.8l3-13.4l1.1,5.4c0.1,0.5,0.5,0.8,1,0.8h5v-2H17.8z"
    })
  },
  'spt-play': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8,5v14l11-7L8,5z"
    })
  },
  'spt-arrow-split-horizontal': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8,18h3v-3H2v-2h20v2h-9v3h3l-4,4L8,18 M12,2L8,6h3v3H2v2h20V9h-9V6h3L12,2z"
    })
  },
  'spt-doc': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "8",
      y: "16",
      className: "st1",
      width: "8",
      height: "2"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      className: "st1",
      d: "M14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8L14,2z M18,20H6V4h7v5h5V20z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "8",
      y: "12",
      className: "st1",
      width: "8",
      height: "2"
    }))
  },
  'spt-calculator': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6,2h12c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V4C4,2.9,4.9,2,6,2 M6,4v4h12V4H7 M7,10v2h2v-2H7 M11,10v2h2 v-2H11 M15,10v2h2v-2H15 M7,14v2h2v-2H7 M11,14v2h2v-2H11 M15,14v2h2v-2H15 M7,18v2h2v-2H7 M11,18v2h2v-2H11 M15,18v2h2v-2H15z"
    })
  },
  'spt-book-variant': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M6,4h5v8l-2.5-1.5L6,12 M18,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z"
    })
  },
  'spt-file-document': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13,9h5.5L13,3.5V9 M6,2h8l6,6v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V4C4,2.9,4.9,2,6,2 M15,18v-2H6v2H15 M18,14v-2H6v2H18 z"
    })
  },
  'spt-bold': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M15.6,10.8c1-0.7,1.7-1.8,1.7-2.8c0-2.3-1.8-4-4-4H7v14h7c2.1,0,3.7-1.7,3.7-3.8C17.8,12.7,16.9,11.4,15.6,10.8z M10,6.5h3 c0.8,0,1.5,0.7,1.5,1.5S13.8,9.5,13,9.5h-3V6.5z M13.5,15.5H10v-3h3.5c0.8,0,1.5,0.7,1.5,1.5S14.3,15.5,13.5,15.5z"
    })
  },
  'spt-italic': {
    path: _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "10,4 10,7 12.2,7 8.8,15 6,15 6,18 14,18 14,15 11.8,15 15.2,7 18,7 18,4 "
    })
  },
  'spt-link': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3.9,12c0-1.7,1.4-3.1,3.1-3.1h4V7H7c-2.8,0-5,2.2-5,5s2.2,5,5,5h4v-1.9H7C5.3,15.1,3.9,13.7,3.9,12z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "8",
      y: "11",
      width: "8",
      height: "2"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17,7h-4v1.9h4c1.7,0,3.1,1.4,3.1,3.1s-1.4,3.1-3.1,3.1h-4V17h4c2.8,0,5-2.2,5-5S19.8,7,17,7z"
    }))
  },
  'spt-list-bulleted': {
    path: _react.default.createElement("g", null, _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "4",
      cy: "12",
      r: "1.5"
    }), _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "4",
      cy: "6",
      r: "1.5"
    }), _react.default.createElement("circle", {
      fill: "currentColor",
      cx: "4",
      cy: "18",
      r: "1.5"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "17",
      width: "14",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "5",
      width: "14",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "11",
      width: "14",
      height: "2"
    }))
  },
  'spt-list-numbered': {
    path: _react.default.createElement("g", null, _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "2,17 4,17 4,17.5 3,17.5 3,18.5 4,18.5 4,19 2,19 2,20 5,20 5,16 2,16 \t"
    }), _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "3,8 4,8 4,4 2,4 2,5 3,5 \t"
    }), _react.default.createElement("polygon", {
      fill: "currentColor",
      points: "2,11 3.8,11 2,13.1 2,14 5,14 5,13 3.2,13 5,10.9 5,10 2,10 \t"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "5",
      width: "14",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "17",
      width: "14",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "11",
      width: "14",
      height: "2"
    }))
  },
  'spt-text-center': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "11",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "19",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "15",
      width: "10",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "7",
      y: "7",
      width: "10",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "3",
      width: "18",
      height: "2"
    }))
  },
  'spt-text-format': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M9.5,12.8h5l0.9,2.2h2.1L12.8,4h-1.5L6.5,15h2.1L9.5,12.8z M12,6l1.9,5h-3.7L12,6z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "5",
      y: "17",
      width: "14",
      height: "2"
    }))
  },
  'spt-text-left': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "7",
      width: "12",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "15",
      width: "12",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "3",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "19",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "11",
      width: "18",
      height: "2"
    }))
  },
  'spt-text-right': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "currentColor",
      x: "9",
      y: "15",
      width: "12",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "11",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "19",
      width: "18",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "9",
      y: "7",
      width: "12",
      height: "2"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "3",
      y: "3",
      width: "18",
      height: "2"
    }))
  },
  'spt-text-justify': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M3 21H21V19H3V21ZM3 17H21V15H3V17ZM3 13H21V11H3V13ZM3 9H21V7H3V9ZM3 3V5H21V3H3Z"
    })
  },
  'spt-break-line': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4 19H10V17H4V19ZM20 5H4V7H20V5ZM17 11H4V13H17.25C18.35 13 19.25 13.9 19.25 15C19.25 16.1 18.35 17 17.25 17H15V15L12 18L15 21V19H17C19.21 19 21 17.21 21 15C21 12.79 19.21 11 17 11Z"
    })
  },
  'spt-add-tag': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84ZM16 17H5V7H16L19.55 12L16 17Z"
    })
  },
  'spt-underline': {
    path: _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12,17c3.3,0,6-2.7,6-6V3h-2.5v8c0,1.9-1.6,3.5-3.5,3.5S8.5,12.9,8.5,11V3H6v8C6,14.3,8.7,17,12,17z"
    }), _react.default.createElement("rect", {
      fill: "currentColor",
      x: "5",
      y: "19",
      width: "14",
      height: "2"
    }))
  },
  'spt-barcode': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M2,6h2v12H2V6 M5,6h1v12H5V6 M7,6h3v12H7V6 M11,6h1v12h-1V6 M14,6h2v12h-2V6 M17,6h3v12h-3V6 M21,6h1v12h-1V6z"
    })
  },
  'spt-file-pdf': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M13,9h5.5L13,3.5V9 M6,2h8l6,6v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V4C4,2.9,4.9,2,6,2 M10.1,11.4c0,0-0.3,1.8-2.1,4.7 c0,0-3.5,1.8-2.7,3.2c0.7,1.1,2.3,0,3.7-2.7c0,0,1.8-0.6,4.2-0.8c0,0,3.9,1.7,4.4-0.1c0.5-1.9-3.1-1.4-3.7-1.2c0,0-2-1.3-2.5-3.2 c0,0,1.1-3.9-0.6-3.9C9.1,7.3,9.8,10.4,10.1,11.4 M10.9,12.4c0,0,0.5,1.2,1.9,2.5c0,0-2.3,0.5-3.4,0.9C9.4,15.8,10.4,14.1,10.9,12.4 M14.8,15.2c0.6-0.2,2.3,0.2,2.3,0.5C17,16,14.8,15.2,14.8,15.2 M7.8,17c-0.5,1.2-1.4,2-1.7,2S6.8,17.4,7.8,17 M10.9,10.1 c0-0.1-0.4-2.2,0-2.1C11.4,8,10.9,10,10.9,10.1z"
    })
  },
  'spt-dynamic-feed': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "none",
      height: "24",
      width: "24"
    })), _react.default.createElement("g", null, _react.default.createElement("g", null), _react.default.createElement("g", null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"
    }))), _react.default.createElement("g", {
      display: "none"
    }, _react.default.createElement("g", {
      display: "inline"
    }), _react.default.createElement("g", {
      display: "inline"
    }, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M8,8H6v7c0,1.1,0.9,2,2,2h9v-2H8V8z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20,3h-8c-1.1,0-2,0.9-2,2v6c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V5C22,3.9,21.1,3,20,3z M20,11h-8V7h8V11z"
    }), _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4,12H2v7c0,1.1,0.9,2,2,2h9v-2H4V12z"
    }))))
  },
  'spt-seat': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M4 18V21H7V18H17V21H20V15H4V18ZM19 10H22V13H19V10ZM2 10H5V13H2V10ZM17 13H7V5C7 3.9 7.9 3 9 3H15C16.1 3 17 3.9 17 5V13Z"
    })
  },
  'spt-block': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 7.58 7.58 4 12 4C13.85 4 15.55 4.63 16.9 5.69L5.69 16.9C4.63 15.55 4 13.85 4 12ZM12 20C10.15 20 8.45 19.37 7.1 18.31L18.31 7.1C19.37 8.45 20 10.15 20 12C20 16.42 16.42 20 12 20Z"
    })
  },
  'spt-column': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7 19H17V4H7V19ZM2 17H6V6H2V17ZM18 6V17H22V6H18Z"
    })
  },
  'spt-speed': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M11 17C11 17.55 11.45 18 12 18C12.55 18 13 17.55 13 17C13 16.45 12.55 16 12 16C11.45 16 11 16.45 11 17ZM11 3V7H13V5.08C16.39 5.57 19 8.47 19 12C19 15.87 15.87 19 12 19C8.13 19 5 15.87 5 12C5 10.32 5.59 8.78 6.58 7.58L12 13L13.41 11.59L6.61 4.79V4.81C4.42 6.45 3 9.05 3 12C3 16.97 7.02 21 12 21C16.97 21 21 16.97 21 12C21 7.03 16.97 3 12 3H11ZM18 12C18 11.45 17.55 11 17 11C16.45 11 16 11.45 16 12C16 12.55 16.45 13 17 13C17.55 13 18 12.55 18 12ZM6 12C6 12.55 6.45 13 7 13C7.55 13 8 12.55 8 12C8 11.45 7.55 11 7 11C6.45 11 6 11.45 6 12Z"
    })
  },
  'spt-remove-circle': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H7V11H17V13Z"
    })
  },
  'spt-photo': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z"
    })
  },
  'spt-repeat': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M7 7H17V10L21 6L17 2V5H5V11H7V7ZM17 17H7V14L3 18L7 22V19H19V13H17V17Z"
    })
  },
  'spt-hospital-sign': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19,3H5A2,2,0,0,0,3.01,5L3,19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM18,14H14v4H10V14H6V10h4V6h4v4h4Z"
    }))
  },
  'spt-credit-card': {
    path: _react.default.createElement("path", {
      d: "M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z",
      fill: "currentColor"
    })
  },
  'spt-video': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17 10.5V7C17 6.45 16.55 6 16 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.55 18 17 17.55 17 17V13.5L21 17.5V6.5L17 10.5Z"
    })
  },
  'spt-flip': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20 5H16.83L15 3H9L7.17 5H4C2.9 5 2 5.9 2 7V19C2 20.1 2.9 21 4 21H20C21.1 21 22 20.1 22 19V7C22 5.9 21.1 5 20 5ZM12 18C9.24 18 7 15.76 7 13H5L7.5 10.5L10 13H8C8 15.21 9.79 17 12 17C12.58 17 13.13 16.87 13.62 16.65L14.36 17.39C13.65 17.76 12.86 18 12 18ZM16.5 15.5L14 13H16C16 10.79 14.21 9 12 9C11.42 9 10.87 9.13 10.38 9.35L9.64 8.62C10.35 8.24 11.14 8 12 8C14.76 8 17 10.24 17 13H19L16.5 15.5Z"
    })
  },
  'spt-mic': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M12 14C13.66 14 14.99 12.66 14.99 11L15 5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V11C9 12.66 10.34 14 12 14ZM17.3 11C17.3 14 14.76 16.1 12 16.1C9.24 16.1 6.7 14 6.7 11H5C5 14.41 7.72 17.23 11 17.72V21H13V17.72C16.28 17.24 19 14.42 19 11H17.3Z"
    })
  },
  'spt-phone-in-talk': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.08 14.82 15.69 14.9 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.78L8.82 8.57C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5ZM19 12H21C21 7.03 16.97 3 12 3V5C15.87 5 19 8.13 19 12ZM15 12H17C17 9.24 14.76 7 12 7V9C13.66 9 15 10.34 15 12Z"
    })
  },
  'spt-video-off': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21 6.5L17 10.5V7C17 6.45 16.55 6 16 6H9.82L21 17.18V6.5ZM3.27 2L2 3.27L4.73 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.21 18 16.39 17.92 16.54 17.82L19.73 21L21 19.73L3.27 2Z"
    })
  },
  'spt-mic-off': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M19 11H17.3C17.3 11.74 17.14 12.43 16.87 13.05L18.1 14.28C18.66 13.3 19 12.19 19 11V11ZM14.98 11.17C14.98 11.11 15 11.06 15 11V5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V5.18L14.98 11.17ZM4.27 3L3 4.27L9.01 10.28V11C9.01 12.66 10.34 14 12 14C12.22 14 12.44 13.97 12.65 13.92L14.31 15.58C13.6 15.91 12.81 16.1 12 16.1C9.24 16.1 6.7 14 6.7 11H5C5 14.41 7.72 17.23 11 17.72V21H13V17.72C13.91 17.59 14.77 17.27 15.54 16.82L19.73 21L21 19.73L4.27 3Z"
    })
  },
  'spt-open-in-full': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M21 11V3H13L16.29 6.29L6.29 16.29L3 13V21H11L7.71 17.71L17.71 7.71L21 11Z"
    })
  },
  'spt-columns': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10 18H15V5H10V18ZM4 18H9V5H4V18ZM16 5V18H21V5H16Z"
    })
  },
  'spt-attachment': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M16.5 6V17.5C16.5 19.71 14.71 21.5 12.5 21.5C10.29 21.5 8.5 19.71 8.5 17.5V5C8.5 3.62 9.62 2.5 11 2.5C12.38 2.5 13.5 3.62 13.5 5V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6H10V15.5C10 16.88 11.12 18 12.5 18C13.88 18 15 16.88 15 15.5V5C15 2.79 13.21 1 11 1C8.79 1 7 2.79 7 5V17.5C7 20.54 9.46 23 12.5 23C15.54 23 18 20.54 18 17.5V6H16.5Z"
    })
  },
  'spt-reply': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M10 9V5L3 12L10 19V14.9C15 14.9 18.5 16.5 21 20C20 15 17 10 10 9Z"
    })
  },
  'spt-waterdrop': {
    path: _react.default.createElement("path", {
      fill: "currentColor",
      d: "M17.66 7.93002L12 2.27002L6.34 7.93002C3.22 11.05 3.22 16.12 6.34 19.24C7.9 20.8 9.95 21.58 12 21.58C14.05 21.58 16.1 20.8 17.66 19.24C20.78 16.12 20.78 11.05 17.66 7.93002ZM12 19.59C10.4 19.59 8.89 18.97 7.76 17.83C6.62 16.69 6 15.19 6 13.59C6 11.99 6.62 10.48 7.76 9.35002L12 5.10002V19.59Z"
    })
  },
  'spt-group': {
    path: _react.default.createElement("path", {
      d: "M16 17C18.1733 17 20.0933 17.52 21.6533 18.2C23.0933 18.84 24 20.28 24 21.84V24H8V21.8533C8 20.28 8.90667 18.84 10.3467 18.2133C11.9067 17.52 13.8267 17 16 17ZM5.33333 17.3333C6.8 17.3333 8 16.1333 8 14.6667C8 13.2 6.8 12 5.33333 12C3.86667 12 2.66667 13.2 2.66667 14.6667C2.66667 16.1333 3.86667 17.3333 5.33333 17.3333ZM6.84 18.8C6.34667 18.72 5.85333 18.6667 5.33333 18.6667C4.01333 18.6667 2.76 18.9467 1.62667 19.44C0.64 19.8667 0 20.8267 0 21.9067V24H6V21.8533C6 20.7467 6.30667 19.7067 6.84 18.8ZM26.6667 17.3333C28.1333 17.3333 29.3333 16.1333 29.3333 14.6667C29.3333 13.2 28.1333 12 26.6667 12C25.2 12 24 13.2 24 14.6667C24 16.1333 25.2 17.3333 26.6667 17.3333ZM32 21.9067C32 20.8267 31.36 19.8667 30.3733 19.44C29.24 18.9467 27.9867 18.6667 26.6667 18.6667C26.1467 18.6667 25.6533 18.72 25.16 18.8C25.6933 19.7067 26 20.7467 26 21.8533V24H32V21.9067ZM16 8C18.2133 8 20 9.78667 20 12C20 14.2133 18.2133 16 16 16C13.7867 16 12 14.2133 12 12C12 9.78667 13.7867 8 16 8Z",
      fill: "currentColor"
    })
  },
  'spt-format-indent-increase': {
    path: _react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
      d: "M0 0h24v24H0z",
      fill: "none"
    }), _react.default.createElement("path", {
      d: "M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z",
      fill: "currentColor"
    }))
  },
  'spt-table-view': {
    path: _react.default.createElement("g", null, _react.default.createElement("rect", {
      fill: "none",
      height: "24",
      width: "24"
    }), _react.default.createElement("path", {
      d: "M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V9C21,7.9,20.1,7,19,7z M19,9v2H9V9H19z M13,15v-2h2v2H13z M15,17v2h-2v-2H15z M11,15H9v-2h2V15z M17,13h2v2h-2V13z M9,17h2v2H9V17z M17,19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2v1h-2V5H5v10h1V17z",
      fill: "currentColor"
    }))
  },
  'spt-clear': {
    path: _react.default.createElement("path", {
      d: "M15 16H19V18H15V16ZM15 8H22V10H15V8ZM15 12H21V14H15V12ZM3 18C3 19.1 3.9 20 5 20H11C12.1 20 13 19.1 13 18V8H3V18ZM5 10H11V18H5V10ZM10 4H6L5 5H2V7H14V5H11L10 4Z",
      fill: "currentColor"
    })
  },
  'spt-text-snippet': {
    path: _react.default.createElement("path", {
      d: "M20.41 8.41L15.58 3.58C15.21 3.21 14.7 3 14.17 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V9.83C21 9.3 20.79 8.79 20.41 8.41ZM7 7H14V9H7V7ZM17 17H7V15H17V17ZM17 13H7V11H17V13Z",
      fill: "currentColor"
    })
  },
  'spt-redo': {
    path: _react.default.createElement("path", {
      d: "M18.4 10.6C16.55 8.99 14.15 8 11.5 8C6.85004 8 2.92004 11.03 1.54004 15.22L3.90004 16C4.95004 12.81 7.95004 10.5 11.5 10.5C13.45 10.5 15.23 11.22 16.62 12.38L13 16H22V7L18.4 10.6Z",
      fill: "white"
    })
  },
  'spt-refresh': {
    path: _react.default.createElement("path", {
      d: "M17.6498 6.35C16.1998 4.9 14.2098 4 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.7298 20 18.8398 17.45 19.7298 14H17.6498C16.8298 16.33 14.6098 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L12.9998 11H19.9998V4L17.6498 6.35Z",
      fill: "currentColor"
    })
  },
  'spt-file-check': {
    path: _react.default.createElement("path", {
      d: "m14,2l-8,0c-1.1,0 -1.99,0.9 -1.99,2l-0.01,16c0,1.1 0.89,2 1.99,2l12.01,0c1.1,0 2,-0.9 2,-2l0,-12l-6,-6zm-3.06,16l-3.54,-3.54l1.41,-1.41l2.12,2.12l4.24,-4.24l1.41,1.41l-5.64,5.66zm2.06,-9l0,-5.5l5.5,5.5l-5.5,0z",
      fill: "currentColor"
    })
  }
};
var _default = icons;
exports.default = _default;