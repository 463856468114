export default {
  geral: {
    app: 'Retaguarda',
    vertical: 'Vertical',

    navegacao: {
      saude: 'Saúde',
      faturas: 'Faturas',
      idiomas: 'Idiomas',
      servicos: 'Serviços',
      empresas: 'Empresas',
      usuarios: 'Usuários',
      educacional: 'Educacional',
      mensalidades: 'Mensalidades'
    },
    feedbacks: {
      arquivoEnviadoSucesso: 'Arquivo enviado com sucesso!',
      arquivoEnviadoErro: 'Erro ao enviar arquivo!',
      somenteUmArquivo: 'Insira apenas um arquivo!',
      insiraArquivo: 'Insira um arquivo!',
      arquivoSQLObrigatorio: 'A extensão do arquivo deve ser .sql'
    }
  },

  dashboard: {
    information: 'Não há nada por aqui :(',
    instruction: 'Cadastre uma empresa para gerar dados na dashboard',
    categorias: {
      contas: 'Contas',
      licencas: 'Lincenças',
      ativacoes: 'Ativaçōes',
      semContas: 'Sem Contas',
      integracoes: 'Integrações (Dados fictícios)',
      semLicencas: 'Sem Licenças',
      semAtivacoes: 'Sem Ativaçōes',
      semIntegracoes: 'Sem Integrações'
    }
  },

  cadastro: {
    empresas: {
      titulo: 'Empresas',
      novo: 'Nova empresa',
      cadastradoSucesso: 'Empresa cadastrada com sucesso!',
      atualizadoSucesso: 'Empresa atualizada com sucesso!',
      removidoSucesso: 'Empresa removida com sucesso!',
      subdominio: 'Subdominio',
      verticalMercado: 'Vertical de mercado',
      codigoCliente: 'Código do cliente',
      perfilAdministrativo: 'Perfil administrativo',
      implantacao: 'Implantação',
      acessarEmpresa: 'Acessar empresa',
      empresaEmProcessamento: 'Empresa em processo de criação. Aguarde!',
      processamentoFalhou: 'O processso de criação falhou! Contate o Gerente de Produtos.',
      tarifas: 'Tarifas',
      debugs: 'Debugs',
      dadosTecnicos: 'Dados Técnicos',
      dadosIntegracao: 'Dados para Integração',
      quadroSocietario: 'Quadro societário',
      novoSocio: 'Adicionar sócio',
      editarSocio: 'Editar sócio',
      tipoIntegracao: 'Tipo da Integração',
      falhas: 'Falhas',
      relacionamento: 'Relacionamento',
      integracao: 'Integração',
      servicos: 'Serviços',
      valor: 'Valor',
      editarValor: 'Editar valor',
      valorServico: 'Valor do serviço',
      tarfifaAtualizadoSucesso: 'Tarifa atualizada com sucesso!',
      servicosInformation: 'Não há nada por aqui :(',
      servicosInstruction: 'Nenhum serviço cadastrado para esta tarifa',
      nenhumaMensagemDebug: 'Nenhuma mensagem de debug registrada',
      mensagem: 'Mensagem',
      data: 'Data',
      nenhumaMensagemFalha: 'Nenhuma mensagem de falha registrada',
      idInterno: 'Id interno',
      idExterno: 'Id externo',
      entidadeOrigem: 'Entidade origem',
      entidadeDestino: 'Entidade destino',
      nenhumRelacionamento: 'Nenhum relacionamento registrado',
      detalhesFalha: 'Detalhes da falha',
      information: 'Information',
      tenantName: 'Tenant',
      tenantId: 'TenantId',
      registryName: 'RegistryName',
      registryId: 'RegistryId',
      timestamp: 'Timestamp',
      retried: 'Retried',
      exceptions: 'Exceptions',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Message',
      source: 'Source',
      host: 'Host',
      machineName: 'MachineName',
      processName: 'ProcessName',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      faultMessageTypes: 'FaultMessageTypes',
      nenhumaException: 'Nenhuma exception registrada',
      actions: 'Actions',
      retry: 'Retry',
      retryEfetuadoSucesso: 'Retry efetuado com sucesso',
      configuracoes: 'Configurações',
      key: 'Key',
      value: 'Value',
      atributos: 'Atributos',
      endpoints: 'Endpoints',
      atributoAtualizadoSucesso: 'Atributo atualizado com sucesso',
      method: 'Method',
      name: 'Name',
      uri: 'URI',
      endpointAtualizadoSucesso: 'Endpoint atualizado com sucesso',
      somenteIntegracao: 'Somente integração',
      id: 'Id para integração',
      hooks: 'Hooks',
      novoHook: 'Novo hook',
      evento: 'Evento',
      url: 'Url',
      metodo: 'Método',
      webhook: 'Webhook',
      hookCriadoSucesso: 'Hook criado com sucesso',
      hookAtualizadoSucesso: 'Hook atualizado com sucesso',
      hookRemovidoSucesso: 'Hook removido com sucesso',
      headers: 'Headers',
      novoHeader: 'Novo header',
      chave: 'Chave',
      headerCriadoSucesso: 'Header criado com sucesso',
      headerAtualizadoSucesso: 'Header atualizado com sucesso',
      headerRemovidoSucesso: 'Header removido com sucesso',
      situacao: 'Situação',
      statusAtualizadoSucesso: 'Status atualizado com sucesso',
      migracao: 'Migração',
      clienteMigracao: 'Cliente Migração',
      migracaoFinal: 'Migração Final',
      idReferenciaTenant: 'ID de referência do Tenant',
      arquivoBancoDeDados: 'Arquivo do Banco de Dados',
      transferenciaAtualizadoSucesso: 'Transferência atualizada com sucesso',
      dataInicialCobranca: 'Data inicial de cobrança',
      backups: {
        baseDados: 'Base de dados',
        solicitacoesBackup: 'Solicitações de Backup',
        editarSolicitacaoBackup: 'Editar solicitação de backup',
        novaSolicitacao: 'Nova Solicitação',
        exclusaoBase: 'Exclusão da base de dados',
        excluirBase: 'Excluir base de dados',
        excluirBaseBtn: 'Excluir base',
        novaSolicitacaoBackup: 'Nova solicitação de backup',
        motivo: 'Motivo',
        dataSolicitacao: 'Data da solicitação',
        situacaoSolicitacao: 'Situação da solicitação',
        gerado: 'Backup gerado',
        pendente: 'Solicitação Pendente',
        recebidoCliente: 'Recebido pelo cliente',
        nomeRecebedor: 'Nome do recebedor',
        cadastradoSucesso: 'Solicitação cadastrada com sucesso',
        atualizadoSucesso: 'Solicitaçaõ atualizada com sucesso',
        valorNaoConfere: 'Os dados informados não conferem. Por favor, verifique.',
        baseDeletadaSucesso: 'Processo concluído com sucesso',
        deletadoPor: 'Responsável pela exclusão',
        dataExclusao: 'Data da exclusão',
        confirmacaoDados: 'Confirmação de dados',
        situacaoContratual: 'Situação do contrato',
        mensagemExclusao: {
          titulo: 'Atenção!',
          conteudo1: 'Este processo de exclusão de base é',
          irreversivel: 'irreversível.',
          conteudo2: 'Ao prosseguir, tenha certeza que o backup foi feito e enviado ao cliente.'
        },
        dialogExclusao: {
          titulo: 'Atenção!',
          baseDe: 'A base de dados da empresa:',
          seraExcluida: 'Será permanentemente excluída.',
          processo: 'Esse processo é ',
          irreversivel: 'irreversível ',
          usuarioRegistrado: 'e seu usuário será registrado como responsável.',
          desejaProsseguir: 'Deseja prosseguir?'
        }
      },

      historicoContratacoes: {
        titulo: 'Histórico de contratações',
        mesAtual: 'Mês atual',
        resumo: 'Resumo',
        movimentoUsuarios: 'Movimento de usuários',
        quantidadeUsuarios: 'Quantidade de usuários',
        valorMensalidade: 'Valor da mensalidade',
        dataMedico: 'Data / Médico',
        emailUsuario: 'E-mail / Usuário',
        nenhumaMovimentacaoEncontrada: 'Nenhuma movimentação encontrada'
      },

      qtdeAlunos: 'Quantidade de alunos',
      qtdeMedicos: 'Quantidade de médicos',
      justificativaAlteracaoTarifaMensalidade: 'Justificativa da alteração de tarifa/mensalidade'
    },

    integracoes: {
      titulo: 'Serviços',
      atualizadoSucesso: 'Serviço atualizado com sucesso!',
      integracaoAtiva: 'Integração Ativa',
      ultimaVerificacao: 'Última verificação',
      preTaxas: 'Pré taxas',
      hooks: 'Hooks',
      nenhumHook: 'Nenhum webhook recebido',
      data: 'Data',
      api: 'API',
      processado: 'Processado',
      webhook: 'Webhook',
      tenantId: 'TenantId',
      tenant: 'Tenant',
      exceptions: 'Exceptions',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Message',
      source: 'Source',
      host: 'Host',
      machineName: 'MachineName',
      processName: 'ProcessName',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      findedRelationship: 'Finded relationship',
      nomeEndpoint: 'Nome endpoint',
      entidadeInterna: 'Entidade interna',
      idInterno: 'Id interno',
      idExterno: 'Id externo',
      fieldInterno: 'Field interno',
      nenhumaExcecao: 'Nenhuma exceção encontrada',
      nenhumRelacionamento: 'Nenhum relacionamento encontrado'
    },

    usuarios: {
      titulo: 'Usuários',
      novo: 'Novo usuário',
      cadastradoSucesso: 'Usuário cadastrado com sucesso!',
      atualizadoSucesso: 'Usuário atualizado com sucesso!'
    }
  },

  financeiro: {
    abertas: 'Abertas',
    fechadas: 'Fechadas',
    finalizadas: 'Finalizadas',
    liquidadas: 'Liquidadas',

    mensalidades: {
      tarifaPadraoMensalidadeAluno: 'Valor por aluno',
      tarifaPadraoMensalidadeMedico: 'Valor por médico',
      novaMensalidade: 'Nova mensalidade',
      tarifaPadraoMensalidade: 'Valor por aluno'
    },
    faturas: {
      fechamentoFatura: 'Fechamento da fatura',
      vencimentoFatura: 'Vencimento da fatura'
    }
  }
}
